import React, { useEffect, useState } from "react";

import { Area } from '@ant-design/plots';

import REQUESTS from "../../../api/requests";

import styles from "../index.module.scss";

const UsedDevicesProgres = ({ dateType = "year", date }) => {
  const [data, setData] = useState([]);

  const config = {
    data,
    xField: dateType === "year" ? 'month' : "day",
    yField: 'count',
    seriesField: 'name',
  };

  const getChart = () => {
    const query = {
      data_type: dateType,
      date,
      type: "platform",
    };

    function collback(data) {
      if (Array.isArray(data)) {
        if (dateType === 'month' || dateType === 'week') {
          data = data.map((obj) => { return { ...obj, count: parseInt(obj.count), day: obj.day.toString() } })
        } else {
          data = data.map((obj) => { return { ...obj, count: parseInt(obj.count) } })
        }

        setData(data);

      } else {
        const clone = { ...data };

        const newData = [];

        for (let platform in clone) {
          for (let value of clone[platform]) {
            if (dateType === 'month' || dateType === 'week') {
              newData.push({ name: platform, count: parseInt(value.count), day: value.day.toString() })
            } else {
              newData.push({ name: platform, count: parseInt(value.count), month: value.month })
            }
          }
        }

        let _newData = newData.sort((a, b) => a.day - b.day)

        setData(_newData);
      }
    }

    function errorCollback(data) { }

    REQUESTS.STATISTICS.DEVICE_CHART(collback, errorCollback, query);
  };

  useEffect(() => {
    getChart();
  }, [date, dateType]);

  return (
    <div className={styles["container"]}>
      <h2 className={styles["title"]}>Devices</h2>
      <Area {...config} style={{ height: 350 }} />
    </div>
  );
};

export default UsedDevicesProgres;
