import REQUESTS from "../../api/requests";
import { useEffect, useState, useContext } from "react";
import { Table, Button, Input, Modal, DatePicker, Menu } from "antd";
import MyIcon from "../../components/myicon";
import ICONS from "../../config/icons";
import classes from "./index.module.scss";
import { useSelector } from "react-redux";
import { selectTranslation } from "../../features/Translation/TranslationSlice";
import TEXT from "../../config/text";
import { SearchOutlined, CalendarOutlined } from "@ant-design/icons";
import getMyDate from "../../components/getMyDate";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import InitialParams from "../../components/InitialParams";
import { emptyContext } from "../../context_empty/context_empty";
const { confirm } = Modal;
const { RangePicker } = DatePicker;

function ReferralEarnings() {
  const [empty, setEmpty] = useContext(emptyContext);
  const [searchParams] = useSearchParams();
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [referralEarnings, setReferralEarnings] = useState([]);
  const [date, setDate] = useState("");
  const [expiredDate, setExpiredDate] = useState("");
  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState({
    comment: "",
  });

  InitialParams(setLimit, setCurrentPage, setSearch, setSort);

  const navigate = useNavigate();
  const goToPosts = (params) =>
    navigate({
      search: `?${createSearchParams(params)}`,
    });

  const translation = useSelector(selectTranslation);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <Input
          allowClear
          autoFocus
          placeholder="Type text here"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
        />
      );
    },
    filterIcon: () => {
      return (
        <>
          <SearchOutlined />
        </>
      );
    },
  });

  // const handleMenuClick = (e, item) => {

  const handleAcceptClick = (item, num) => {
    const body = {
      id: item.id,
      status: num,
    };
    function callback() {
      getReferralEarningsList();
    }

    function errorCallback(err) {}

    REQUESTS.EARNINGS.EDIT(body, callback, errorCallback);
  };

  const showPropsConfirm = (item, text, num) => {
    confirm({
      content: `${
        translation["Are you sure you want to delete?"] ||
        TEXT["Are you sure you want to delete?"]
      }`,
      okText: `${text}`,
      okType: "default",
      okButtonProps: {
        disabled: false,
        style: {
          backgroundColor: "#bf4342",
          color: "#fff",
        },
        loading: loading,
      },

      cancelText: `${translation["CANCEL"] || TEXT["CANCEL"]}`,
      onOk() {
        handleAcceptClick(item, num);
      },

      onCancel() {},
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: "60px",
      align: "center",
      render: (text, record, index) => {
        if (currentPage === 1) {
          return index + 1;
        } else {
          return limit * (currentPage - 1) + index + 1;
        }
      },
    },

    {
      title: `${translation["Resellers"] || TEXT["Resellers"]}`,
      dataIndex: "reseller",
      key: "name",
      align: "center",
      render: (record, text, index) => {
        if (record?.name && record?.surname) {
          return <p>{`${record?.name} ${record?.surname}`}</p>;
        } else {
          return <p>N/A</p>;
        }
      },
      ...getColumnSearchProps(),
    },

    {
      title: `${translation["Amount"] || TEXT["Amount"]}`,
      dataIndex: "amount",
      key: "amount",
      align: "center",
      sorter: true,
    },

    {
      title: `${translation["Date"] || TEXT["Date"]}`,
      dataIndex: "createdAt",
      align: "center",
      sorter: true,
      render: (record) => {
        return getMyDate(record);
      },
      filterDropdown: ({}) => (
        <div
          style={{
            padding: 8,
          }}
        >
          <RangePicker
            size="small"
            onChange={(e, dateString) => setDate(dateString)}
            renderExtraFooter={() => "extra footer"}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <CalendarOutlined
          style={{
            color: JSON.parse(searchParams.get("between"))?.createdAt
              ? "#1890ff"
              : undefined,
          }}
        />
      ),
    },

    {
      title: `${translation["Expired Date"] || TEXT["Expired Date"]}`,
      dataIndex: "expired_date",
      align: "center",
      sorter: true,
      render: (record) => {
        return getMyDate(record);
      },
      filterDropdown: ({}) => (
        <div
          style={{
            padding: 8,
          }}
        >
          <RangePicker
            size="small"
            onChange={(e, dateString) => setExpiredDate(dateString)}
            renderExtraFooter={() => "extra footer"}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <CalendarOutlined
          style={{
            color: JSON.parse(searchParams.get("between"))?.expired_date
              ? "#1890ff"
              : undefined,
          }}
        />
      ),
    },

    {
      title: `${translation["Status"] || TEXT["Status"]}`,
      width: "180px",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record, index) => {
        if (text === 1) {
          return <MyIcon children={ICONS.CHECK} />;
        } else if (text === 2) {
          return <MyIcon children={ICONS.TIMES} />;
        } else if (text === 0) {
          return (
            <div>
              <Menu
                mode="horizontal"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "170px",
                }}
              >
                {/* <div className={classes["ReferralEarnings_button_div"]}> */}
                <Button
                  className={classes["ReferralEarnings_button"]}
                  onClick={() => {
                    showPropsConfirm(
                      record,
                      `${translation["ACCEPT"] || TEXT["ACCEPT"]}`,
                      1
                    );
                  }}
                  style={{ backgroundColor: " #28857c", color: "white" }}
                >
                  {translation["Accept"] || TEXT["Accept"]}
                </Button>

                <Button
                  className={classes["ReferralEarnings_button"]}
                  onClick={() => {
                    showPropsConfirm(
                      record,
                      `${translation["REJECT"] || TEXT["REJECT"]}`,
                      2
                    );
                  }}
                  style={{ backgroundColor: "#bf4342", color: "white" }}
                >
                  {translation["Reject"] || TEXT["Reject"]}
                </Button>
                {/* </div> */}
              </Menu>
            </div>
          );
        }
      },
    },
  ];

  const getReferralEarningsList = () => {
    setLoading(true);
    const query = {
      page: currentPage,
      limit,
      pagination: 1,
      sort: JSON.stringify(sort),
      search: {},
    };

    if (search.name) {
      query.search["$name$"] = search.name[0];
    }

    if (query.search) {
      query.search = JSON.stringify(query.search);
    }

    let dataArray = [
      { date: date, betweenDate: "createdAt" },
      { date: expiredDate, betweenDate: "expired_date" },
    ];

    for (let i = 0; i < dataArray.length; i++) {
      if (dataArray[i].date && dataArray[i].date[0]) {
        let to = new Date(dataArray[i].date[1]);
        to.setDate(to.getDate() + 1);
        if (query.between) {
          query.between = JSON.stringify({
            ...JSON.parse(query?.between),
            [dataArray[i].betweenDate]: {
              from: new Date(dataArray[i].date[0]),
              to: new Date(to),
            },
          });
        } else {
          query.between = JSON.stringify({
            [dataArray[i].betweenDate]: {
              from: new Date(dataArray[i].date[0]),
              to: new Date(to),
            },
          });
        }
      }
    }

    // if (date && date[0]) {
    //   let to = new Date(date[1]);
    //   to.setDate(to.getDate() + 1);
    //   query.between = JSON.stringify({
    //     createdAt: {
    //       from: new Date(date[0]),
    //       to: new Date(to),
    //     },
    //   });
    // }

    // if (expiredDate && expiredDate[0]) {
    //   let to = new Date(expiredDate[1]);
    //   to.setDate(to.getDate() + 1);
    //   query.between = JSON.stringify({
    //     expired_date: {
    //       from: new Date(expiredDate[0]),
    //       to: new Date(to),
    //     },
    //   });
    // }

    goToPosts(query);

    REQUESTS.EARNINGS.GET(query, (data) => {
      setTotal(data.count);
      // setLimit(data.limit);
      setLoading(false);
      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }

      setReferralEarnings(data.rows);

      if (!data.rows == []) {
        setEmpty(false);
      }
    });
  };

  useEffect(() => {
    return () => {
      setEmpty(true);
    };
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setSearch(filters);

    if (Object.values(filters)[0]) {
      //   setTitleSearch(Object.values(filters)[0].join(""));
    } else {
      //   setTitleSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  useEffect(() => {
    let timout = setTimeout(() => {
      getReferralEarningsList();
    }, 500);
    return () => {
      clearTimeout(timout);
    };
  }, [search, currentPage, limit, date, sort, expiredDate]);

  return (
    <div className={classes["ReferralEarnings_list_table"]}>
      <div className={classes["ReferralEarnings_list_title"]}>
        <p className={classes["ReferralEarnings_title-text"]}>
          {/* <DollarCircleOutlined />{" "} */}
          {translation["Referral Earnings"] || TEXT["Referral Earnings"]}
        </p>
      </div>

      <Table
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={referralEarnings}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
          // defaultPageSize: 1,
        }}
        scroll={{ x: "max-content" }}
        size="small"

      />
    </div>
  );
}

export default ReferralEarnings;
