import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ConfigProvider } from "antd";

import REQUESTS from "./api/requests";

import DashboardPage from "./layout";

import NewsPage from "./pages/news";
import Support from "./pages/support";
import Statuses from "./pages/Statuses";
import Withdraw from "./pages/withdraw";
import Settings from "./pages/settings";
import Login from "./pages/login/index";
import Blacklist from "./pages/blacklist";
import PackagePage from "./pages/packages";
import Activation from "./pages/activation";
import UserPackages from "./pages/userPackages";
import RealTimeChat from "./pages/realTimeChat";
import Dashboard from "./pages/dashboard/index";
import ReferralLinks from "./pages/referralLinks";
import PaymentHistory from "./pages/paymentHistory";
import ResetPasswordPage from "./pages/resetPassword";
import DeviceManager from "./pages/deviceManager/index";
import ReferralReseller from "./pages/referralReseller";
import ReferralEarnings from "./pages/referralEarnings";
import PrivateRoute from "./pages/privateRoute/privateRoute";

import { selectappInfo } from "./features/appInfoSlice/AppInfoSlice";
import { editTranslation } from "./features/Translation/TranslationSlice";
import { editAppInfo } from "./features/appInfoSlice/AppInfoSlice";
import { emptyContext } from "./context_empty/context_empty";
import { editwidgets } from "./features/widgets/actions";
import "./App.css";
import "./index.scss"

import "antd/dist/antd.variable.min.css";
import Coupons from "./pages/coupons";
import CONSTANTS from "./config";
import ViewMoreSeriesStatistics from "./pages/dashboard/components/viewMoreSeriesStatistics";
import Referral from "./pages/referral/Referral";
import Reseller from "./pages/reseller/Reseller";
import Notification from "./pages/notification/Notification";
import ManageCategories from "./pages/manageCategories/ManageCategories";
import Applications from "./pages/applications";
import FreeDNS from "./pages/freeDNS";

import PlaylistsPage from "./pages/playlists";

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}></div>;

function App () {
  const dispatch = useDispatch();

  const appInfo = useSelector(selectappInfo);

  const [empty, setEmpty] = useState(true);

  // const [loading, setLoading] = useState(true);

  const getTranslations = () => {
    function collback (data) {
      dispatch(editTranslation(data));
    }

    function errorCollback (data) {
      // dispatch(editTranslation(data));
    }

    REQUESTS.TEXT_TRANSLATION(collback, errorCollback, { id: 1 });
  };

  ConfigProvider.config({
    theme: {
      primaryColor: CONSTANTS.APP_COLOR,
    },
  });

  const getwidgets = () => {
    // setLoading(true);
    const collback = (data) => {
      // setLoading(false);
      dispatch(editwidgets(data));
    };
    REQUESTS.WIDGETS.GET(collback);
  };

  const getAppInfo = () => {
    // setLoading(true);
    const collback = (data) => {
      dispatch(editAppInfo(data));
      getwidgets();

      const root = document.documentElement;

      root.style.setProperty("--app-color", CONSTANTS.APP_COLOR);
      root.style.setProperty("--sidebar_item_color", data.sidebar_item_color);
      root.style.setProperty("--active-color", data.sidebar_item_active_color);
      root.style.setProperty("--active-background", data.sidebar_item_active_background);
      root.style.setProperty("--logo-size", data.sidebar_logo_size);
    };

    REQUESTS.APP_INFO.GET(collback);
  };

  useEffect(() => {
    getAppInfo();
    getTranslations();
  }, []);

  return (
    <div className="app">
      <ConfigProvider renderEmpty={empty ? customizeRenderEmpty : undefined}>
        <emptyContext.Provider value={[empty, setEmpty]}>
          <Helmet>
            <title>
              {window.APP_NAME || "ADMIN Panel"}
            </title>
            <link
              rel="icon"
              href={appInfo?.favicon}
              type="image/x-icon"
            />
          </Helmet>

          {/* {loading && <div className="full-screen-loading"></div>} */}

          <Routes>
            <Route path="/" element={<Navigate replace to="login" />} />
            {/* <Route path="/" element={<Login />} /> */}
            <Route path="/login" element={<Login />} />
            <Route path={"/reset_password"} element={<ResetPasswordPage />} />
            <Route
              path="/DashboardPage"
              element={
                <PrivateRoute>
                  <DashboardPage />
                </PrivateRoute>
              }
            >
              <Route index element={<Dashboard />} />
              <Route path="deviceManager" element={<DeviceManager />} />
              <Route path="referralReseller" element={<ReferralReseller />} />

              <Route path="referralpackages" element={<PackagePage />} />

              <Route path="userPackages" element={<UserPackages />} />

              <Route path="blacklist" element={<Blacklist />} />

              <Route path="referralwithdraw" element={<Withdraw />} />

              <Route path="referralEarnings" element={<ReferralEarnings />} />

              <Route path="referralactivation" element={<Activation />} />

              <Route path="paymentHistory" element={<PaymentHistory />} />
              <Route path="referralLinks" element={<ReferralLinks />} />
              <Route path="referralStatuses" element={<Statuses />} />

              <Route path="referral" element={<Referral />} />
              <Route path="reseller" element={<Reseller />} />

              <Route path="settings" element={<Settings />} />

              <Route path="newsPage" element={<NewsPage />} />

              <Route path="support" element={<Support />} />

              <Route path="chat" element={<RealTimeChat />} />

              <Route path="GIFTCode" element={<Coupons />} />
              <Route
                path="serias-statistics"
                element={<ViewMoreSeriesStatistics />}
              />
              <Route path="notification" element={<Notification />} />

              <Route path="manage-categories" element={<ManageCategories />} />

              <Route path="applications" element={<Applications />} />
              <Route path="free-dns" element={<FreeDNS />} />
              <Route path="playlists" element={<PlaylistsPage />} />

            </Route>
            <Route
              path="*" element={<Navigate replace to="/DashboardPage" />}
            />
          </Routes>
        </emptyContext.Provider>
      </ConfigProvider>
    </div>
  );
}

export default App;
