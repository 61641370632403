import React, { useEffect, useState } from 'react';
import { Popover, Table, DatePicker, Input, Button, Space } from 'antd';
import { CheckOutlined, CloseOutlined, CalendarOutlined, SearchOutlined } from '@ant-design/icons';
import getMyDate from '../../components/getMyDate';
import REQUESTS from '../../api/requests';
import DeviceManagerDrawer from '../deviceManager/components/DeviceManagerDrawer';
import styles from "./index.module.scss";

const { RangePicker } = DatePicker;

const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => confirm()}
                style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => confirm()}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </Space>
        </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
});

export default function PlaylistsPage() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(10);
    const [limit, setLimit] = useState(10);
    const [sorter, setSorter] = useState(["id", "ASC"]);
    const [search, setSearch] = useState({
        deviceId: null,
        name: null,
        url: null,
    });
    const [date, setDate] = useState(null);
    const [expireDate, setExpireDate] = useState(null);
    const [current, setCurrent] = useState(null);
    const [open, setOpen] = useState(false);

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            align: "center",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Device Id",
            dataIndex: "deviceId",
            align: "center",
            sorter: true,
            ...getColumnSearchProps('deviceId'),
        },
        {
            title: "Name",
            dataIndex: "name",
            align: "center",
            sorter: true,
            ...getColumnSearchProps('name'),
        },
        {
            title: "Url",
            dataIndex: "url",
            key: 'url',
            align: "center",
            sorter: true,
            ...getColumnSearchProps('url'),
            ellipsis: {
                showTitle: false,
            },
            render: (text) => text && (
                <Popover content={text}>
                    <a href={text} target="_blank"
                        style={{
                            color: "#1890ff",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {text}
                    </a>
                </Popover>
            ),
        },
        {
            title: "Free DNS",
            dataIndex: "free_dns_id",
            align: "center",
            render: (text) => text ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />,
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            align: "center",
            render: (text) => text ? getMyDate(text) : "N/A",
            filterDropdown: () => (
                <div style={{ padding: 8 }}>
                    <RangePicker
                        size="small"
                        onChange={(e, dateString) => setDate(dateString)}
                        renderExtraFooter={() => "extra footer"}
                    />
                </div>
            ),
            filterIcon: () => <CalendarOutlined />,
        },
        {
            title: "Protected",
            dataIndex: "pin",
            align: "center",
            render: (text, record) => record?.is_protected ? record.pin : <CloseOutlined style={{ color: "red" }} />,
        },
        {
            title: "Status",
            dataIndex: "status",
            align: "center",
            sorter: true,
            render: (text) => text || "-",
        },
        {
            title: "Expired date",
            dataIndex: "expired_date",
            align: "center",
            render: (text) => text ? getMyDate(text) : "N/A",
            filterDropdown: () => (
                <div style={{ padding: 8 }}>
                    <RangePicker
                        size="small"
                        onChange={(e, dateString) => setExpireDate(dateString)}
                        renderExtraFooter={() => "extra footer"}
                    />
                </div>
            ),
            filterIcon: () => <CalendarOutlined />,
        },
        {
            title: "Selected",
            align: "center",
            render: (record) => record.is_selected ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />,
        },
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
        setSearch(filters);
        setSorter([sorter.field || "id", sorter.order === "ascend" ? "ASC" : "DESC"]);
    };

    const getPlaylists = () => {
        const query = {
            page: currentPage,
            limit,
            pagination: 1,
            sort: JSON.stringify(sorter),
        };

        setLoading(true);

        if (search.deviceId || search.name || search.url) {
            const searchParams = {};
            if (search.name) searchParams.name = search.name[0];
            if (search.deviceId) searchParams.deviceId = search.deviceId[0];
            if (search.url) searchParams.url = search.url[0];

            query.search = JSON.stringify(searchParams);
        }

        const dateArray = [
            { date: date, betweenDate: "createdAt" },
            { date: expireDate, betweenDate: "expired_date" },
        ];

        for (let i = 0; i < dateArray.length; i++) {
            if (dateArray[i].date && dateArray[i].date[0]) {
                query.between = JSON.stringify({
                    [dateArray[i].betweenDate]: {
                        from: dateArray[i].date[0] + ' 00:00',
                        to: dateArray[i].date[1] + " 23:59",
                    },
                });
            }
        }

        REQUESTS.GET_PLAYLISTS(query,
            (data) => {
                setTotal(data.count);
                setLoading(false);
                setData(data.rows);
            },
            () => {
                setLoading(false);
            }
        );
    };

    const getDevice = (id) => {
        if (!id) return setCurrent(null);

        const query = {
            filter: JSON.stringify({ id }),
        };

        REQUESTS.DEVICE.GET(query, (data) => {
            setCurrent(data.rows[0]);
        });
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            getPlaylists();
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [sorter, search, currentPage, limit, date, expireDate]);

    return (
        <div>
            <h1 className='table-title'>Playlists</h1>
            <Table
                loading={loading}
                rowKey="id"
                columns={columns}
                dataSource={data}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                }}
                rowClassName={() => styles["row"]}
                onRow={(record) => ({
                    onClick: () => {
                        setOpen(true);
                        getDevice(record.deviceId);
                    },
                })}
                scroll={{ x: "max-content" }}
                size="small"
            />
            <DeviceManagerDrawer
                visible={open}
                onClose={() => {
                    setOpen(false);
                    setCurrent(null);
                }}
                userInfo={current}
            />
        </div>
    );
}
