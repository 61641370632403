import { useState, useEffect } from "react";

import { useNavigate, createSearchParams } from "react-router-dom";

import { useSelector } from "react-redux";

import { Button, Form, Input, notification, Select, Switch } from "antd";

import { selectwidgets } from "../../../features/widgets/selector";

import TEXT from "../../../config/text";

import REQUESTS from "../../../api/requests";

import { selectTranslation } from "../../../features/Translation/TranslationSlice";

import "../index.scss";

export default function PaymentSettings() {
  const navigate = useNavigate();

  const translation = useSelector(selectTranslation);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [currencies, setCurrencies] = useState([]);

  const [paypalEnabled, setPaypalEnabled] = useState(false);

  const [stripeEnabled, setStripeEnabled] = useState(false);

  const [cmiEnabled, setCmiEnabled] = useState(false);

  const [pixiEnabled, setPixiEnabled] = useState(false);

  const [coinbaseEnabled, setCoinBaseEnabled] = useState(false);

  const [amazoneEnabled, setAmazonEnabled] = useState(false);
  const [myFatoorahEnabled, setMyFatoorahEnabled] = useState(false);

  const widgets = useSelector(selectwidgets);

  const getCurrencies = () => {
    const collback = (data) => {
      setCurrencies(data.rows);
    };

    REQUESTS.APP_INFO.CURRENCIES({ limit: 120 }, collback);
  };

  const getPaymentSettings = () => {
    REQUESTS.PAYMENT_SETTINGS((data) => {

      form.setFields([
        {
          name: "currency",
          value: data.currency,
        },

        {
          name: "paypal_enabled",
          value: data.paypal_enabled,
        },
        {
          name: "show_paypal_card",
          value: data.show_paypal_card,
        },

        {
          name: "paypal_client_id",
          value: data.paypal_client_id,
        },

        {
          name: "paypal_client_secret",
          value: data.paypal_client_secret,
        },

        {
          name: "stripe_enabled",
          value: data.stripe_enabled,
        },

        {
          name: "stripe_public_key",
          value: data.stripe_public_key,
        },

        {
          name: "stripe_secret_key",
          value: data.stripe_secret_key,
        },

        {
          name: "stripe_webhook_secret",
          value: data.stripe_webhook_secret,
        },

        {
          name: "cmi_enabled",
          value: data.cmi_enabled,
        },

        {
          name: "geteway_url",
          value: data.geteway_url,
        },

        {
          name: "cmi_client_id",
          value: data.cmi_client_id,
        },

        {
          name: "cmi_store_key",
          value: data.cmi_store_key,
        },
        {
          name: "dlocal_enabled",
          value: data.dlocal_enabled,
        },
        {
          name: "dlocal_x_login",
          value: data.dlocal_x_login,
        },
        {
          name: "dlocal_secret_key",
          value: data.dlocal_secret_key,
        },
        {
          name: "dlocal_x_trans_key",
          value: data.dlocal_x_trans_key,
        },
        {
          name: "coinbase_enabled",
          value: data.coinbase_enabled,
        },
        {
          name: "coinbase_api_key",
          value: data.coinbase_api_key,
        },
        {
          name: "amazon_enabled",
          value: data.amazon_enabled,
        },
        {
          name: "amazon_merchant_id",
          value: data.amazon_merchant_id,
        },
        {
          name: "amazon_private_key",
          value: data.amazon_private_key,
        },
        {
          name: "amazon_public_key_id",
          value: data.amazon_public_key_id,
        },
        {
          name: "amazon_store_id",
          value: data.amazon_store_id,
        },
        {
          name: "my_fatoorah_enabled",
          value: data.my_fatoorah_enabled,
        },
        {
          name: "my_fatoorah_currency",
          value: data.my_fatoorah_currency,
        },
        {
          name: "my_fatoorah_api",
          value: data.my_fatoorah_api,
        },
        {
          name: "my_fatoorah_api_key",
          value: data.my_fatoorah_api_key,
        },
        {
          name: "my_fatoorah_secret_key",
          value: data.my_fatoorah_secret_key,
        },
      ]);

      setPaypalEnabled(data.paypal_enabled);
      setStripeEnabled(data.stripe_enabled);
      setCmiEnabled(data.cmi_enabled);
      setPixiEnabled(data.dlocal_enabled);
      setCoinBaseEnabled(data.coinbase_enabled);
      setAmazonEnabled(data.amazon_enabled);
      setMyFatoorahEnabled(data.my_fatoorah_enabled);
    });
  };

  const onFinish = (values) => {
    const {
      paypal_enabled,
      paypal_client_id,
      paypal_client_secret,
      stripe_enabled,
      stripe_public_key,
      stripe_secret_key,
      stripe_webhook_secret,
      cmi_enabled,
      geteway_url,
      show_paypal_card,
      cmi_client_id,
      cmi_store_key,
      dlocal_enabled,
      dlocal_x_login,
      dlocal_secret_key,
      dlocal_x_trans_key,
      coinbase_enabled,
      coinbase_api_key,
      currency,
      amazon_enabled,
      amazon_merchant_id,
      amazon_private_key,
      amazon_public_key_id,
      amazon_store_id,
      my_fatoorah_enabled,
      my_fatoorah_currency,
      my_fatoorah_api,
      my_fatoorah_api_key,
      my_fatoorah_secret_key,
    } = values;
    setLoading(true);

    function callback() {
      getPaymentSettings();

      notification.success({
        description: "Success",
      });

      setLoading(false);
    }

    function errorCallback(err) {
      if (typeof err === "string") {
        notification.error({
          description: err,
        });
      }
      setLoading(false);
    }

    const body = {
      currency,
      paypal_enabled,
      paypal_client_id: paypal_client_id || "",
      paypal_client_secret: paypal_client_secret || "",
      stripe_enabled,
      stripe_public_key: stripe_public_key || "",
      stripe_secret_key: stripe_secret_key || "",
      stripe_webhook_secret: stripe_webhook_secret || "",
      cmi_enabled,
      cmi_client_id: cmi_client_id || "",
      cmi_store_key: cmi_store_key || "",
      geteway_url: geteway_url || "",
      show_paypal_card: show_paypal_card,
      dlocal_enabled,
      dlocal_x_login: dlocal_x_login || "",
      dlocal_secret_key: dlocal_secret_key || "",
      dlocal_x_trans_key: dlocal_x_trans_key || "",
      coinbase_enabled,
      coinbase_api_key: coinbase_api_key || "",
      amazon_enabled: amazon_enabled,
      amazon_merchant_id: amazon_merchant_id || "",
      amazon_private_key: amazon_private_key || "",
      amazon_public_key_id: amazon_public_key_id || "",
      amazon_store_id: amazon_store_id || "",
      my_fatoorah_enabled,
      my_fatoorah_currency,
      my_fatoorah_api,
      my_fatoorah_api_key,
      my_fatoorah_secret_key,
    };

    REQUESTS.EDIT_PAYMENT_SETTINGS(body, callback, errorCallback);
  };

  const onValuesChange = (e, all) => {
    setPaypalEnabled(all.paypal_enabled);
    setStripeEnabled(all.stripe_enabled);
    setCmiEnabled(all.cmi_enabled);
    setPixiEnabled(all.dlocal_enabled);
    setCoinBaseEnabled(all.coinbase_enabled);
    setAmazonEnabled(all.amazon_enabled);
    setMyFatoorahEnabled(all.my_fatoorah_enabled);
  };

  useEffect(() => {
    getCurrencies();
    getPaymentSettings();

    navigate({
      search: `?${createSearchParams({ page: "paymentSettings" })}`,
    });
  }, []);

  return (
    <Form
      form={form}
      name="payments-config"
      layout="vertical"
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      className="payment-settings-tab-form"
    >
      <div className="payment-types-container">
      <div className="payment-type">
          <Form.Item
            name="cmi_enabled"
            valuePropName="checked"
            label={translation["CMI enabled"] || TEXT["CMI enabled"]}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="geteway_url"
            label={translation["Gateway url"] || TEXT["Gateway url"]}
            rules={[
              {
                required: cmiEnabled,
                message: "Please enter getway url",
              },
            ]}
          >
            <Input disabled={!cmiEnabled} />
          </Form.Item>
          <Form.Item
            name="cmi_client_id"
            label={translation["CMI client id"] || TEXT["CMI client id"]}
            rules={[
              {
                required: cmiEnabled,
                message: "Please enter your CMI client id",
              },
            ]}
          >
            <Input disabled={!cmiEnabled} />
          </Form.Item>
          <Form.Item
            name="cmi_store_key"
            label={translation["CMI store key"] || TEXT["CMI store key"]}
            rules={[
              {
                required: cmiEnabled,
                message: "Please enter your CMI store key",
              },
            ]}
          >
            <Input disabled={!cmiEnabled} />
          </Form.Item>
        </div>
        
        <div className="payment-type">
          <Form.Item
            name="paypal_enabled"
            valuePropName="checked"
            label={translation["Paypal enabled"] || TEXT["Paypal enabled"]}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="show_paypal_card"
            valuePropName="checked"
            label={translation["Show paypal card"] || TEXT["Show paypal card"]}
          >
            <Switch disabled={!paypalEnabled} />
          </Form.Item>
          <Form.Item
            name="paypal_client_id"
            label={translation["Paypal client id"] || TEXT["Paypal client id"]}
            rules={[
              {
                required: paypalEnabled,
                message: "Please enter your paypal client id",
              },
            ]}
          >
            <Input disabled={!paypalEnabled} />
          </Form.Item>
          <Form.Item
            name="paypal_client_secret"
            label={
              translation["Paypal client secret"] || TEXT["Paypal client secret"]
            }
            rules={[
              {
                required: paypalEnabled,
                message: "Please enter your paypal client secret",
              },
            ]}
          >
            <Input disabled={!paypalEnabled} />
          </Form.Item>
        </div>

        <div className="payment-type">
          <Form.Item
            name="stripe_enabled"
            valuePropName="checked"
            label={translation["Stripe enabled"] || TEXT["Stripe enabled"]}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="stripe_public_key"
            label={translation["Stripe public key"] || TEXT["Stripe public key"]}
            rules={[
              {
                required: stripeEnabled,
                message: "Please enter your stripe public key",
              },
            ]}
          >
            <Input disabled={!stripeEnabled} />
          </Form.Item>
          <Form.Item
            name="stripe_secret_key"
            label={translation["Stripe secret key"] || TEXT["Stripe secret key"]}
            rules={[
              {
                required: stripeEnabled,
                message: "Please enter your stripe secret key",
              },
            ]}
          >
            <Input disabled={!stripeEnabled} />
          </Form.Item>
          <Form.Item
            name="stripe_webhook_secret"
            rules={[
              {
                required: stripeEnabled,
                message: "Please enter your stripe webhook secret",
              },
            ]}
            label={
              translation["Stripe webhook secret"] || TEXT["Stripe webhook secret"]
            }
          >
            <Input disabled={!stripeEnabled} />
          </Form.Item>
        </div>


        {
          widgets && widgets.dlocal_pay && widgets.dlocal_pay.enabled === "true" &&
          <div className="payment-type">
            <Form.Item
              name="dlocal_enabled"
              valuePropName="checked"
              label={translation["Pixi enabled"] || TEXT["Pixi enabled"]}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              name="dlocal_secret_key"
              label={translation["Pixi secret key"] || TEXT["Pixi secret key"]}
              rules={[
                {
                  required: pixiEnabled,
                  message: "Please enter your Pixi secret key",
                },
              ]}
            >
              <Input disabled={!pixiEnabled} />
            </Form.Item>
            <Form.Item
              name="dlocal_x_login"
              label={translation["Pixi x login key"] || TEXT["Pixi x login key"]}
              rules={[
                {
                  required: pixiEnabled,
                  message: "Please enter your Pixi x login key",
                },
              ]}
            >
              <Input disabled={!pixiEnabled} />
            </Form.Item>
            <Form.Item
              name="dlocal_x_trans_key"
              label={translation["Pixi x transaction key"] || TEXT["Pixi x transaction key"]}
              rules={[
                {
                  required: pixiEnabled,
                  message: "Please enter your Pixi x transaction key",
                },
              ]}
            >
              <Input disabled={!pixiEnabled} />
            </Form.Item>
          </div>
        }
        {
          widgets && widgets.coinbase_pay && widgets.coinbase_pay.enabled === "true" &&
          <div className="payment-type">
            <Form.Item
              name="coinbase_enabled"
              valuePropName="checked"
              label={translation["Coinbase enabled"] || TEXT["Coinbase enabled"]}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="coinbase_api_key"
              label="Coinbase API key"
              rules={[
                {
                  required: coinbaseEnabled,
                  message: "Please enter your Coinbase api key",
                },
              ]}
            >
              <Input disabled={!coinbaseEnabled} />
            </Form.Item>
          </div>
        }
        {
          widgets && widgets.amazon_pay && widgets.amazon_pay.enabled === "true" &&
          <div className="payment-type">
            <Form.Item
              name="amazon_enabled"
              valuePropName="checked"
              label="Amazon enabled"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="amazon_merchant_id"
              label="Amazon merchant id"
              rules={[
                {
                  required: amazoneEnabled,
                  message: "Please enter amazon merchant id",
                },
              ]}
            >
              <Input disabled={!amazoneEnabled} />
            </Form.Item>
            <Form.Item
              name="amazon_private_key"
              label="Amazon private key"
              rules={[
                {
                  required: amazoneEnabled,
                  message: "Please enter amazon private key",
                },
              ]}
            >
              <Input
                disabled={!amazoneEnabled}
              />
            </Form.Item>
            <Form.Item
              name="amazon_public_key_id"
              label="Amazon public key id"
              rules={[
                {
                  required: amazoneEnabled,
                  message: "Please enter amazon public key id",
                },
              ]}
            >
              <Input
                disabled={!amazoneEnabled}
              />
            </Form.Item>
            <Form.Item
              name="amazon_store_id"
              label="Amazon store id"
              rules={[
                {
                  required: amazoneEnabled,
                  message: "Please enter amazon store id",
                },
              ]}
            >
              <Input
                disabled={!amazoneEnabled}
              />
            </Form.Item>
          </div>
        }
        <div className="payment-type">
          <Form.Item
            name="my_fatoorah_enabled"
            valuePropName="checked"
            label="My Fatoorah enabled"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="my_fatoorah_currency"
            label="My Fatoorah currency"
            rules={[
              {
                required: myFatoorahEnabled,
                message: "Please enter currency",
              },
            ]}
          >
            <Input disabled={!myFatoorahEnabled} />
          </Form.Item>
          <Form.Item
            name="my_fatoorah_api"
            label="My Fatoorah API"
            rules={[
              {
                required: myFatoorahEnabled,
                message: "Please enter API",
              },
            ]}
          >
            <Input disabled={!myFatoorahEnabled} />
          </Form.Item>
          <Form.Item
            name="my_fatoorah_api_key"
            label="My Fatoorah API key"
            rules={[
              {
                required: myFatoorahEnabled,
                message: "Please enter API key",
              },
            ]}
          >
            <Input disabled={!myFatoorahEnabled} />
          </Form.Item>
          <Form.Item
            name="my_fatoorah_secret_key"
            label="My Fatoorah secret key"
            rules={[
              {
                required: myFatoorahEnabled,
                message: "Please enter secret key",
              },
            ]}
          >
            <Input disabled={!myFatoorahEnabled} />
          </Form.Item>
        </div>
      </div>

      <div className="payment-type">
        <Form.Item
          name="currency"
          label={translation["Currency"] || TEXT["Currency"]}
          rules={[
            {
              required: cmiEnabled,
              message: "Please select currency",
            },
          ]}
        >
          <Select showSearch={true}>
            {currencies.map((item) => {
              return (
                <Select.Option key={item.code} value={`${item.code}`}>
                  {item.code}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
            block
          >
            {translation["Save"] || TEXT["Save"]}
          </Button>
        </Form.Item>
      </div>

    </Form>
  );
}
