import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectwidgets } from "../../features/widgets/selector";

import { Table, Button, Input, Modal, Switch, Popover } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import TableButtons from "../../components/TableButtons";
import classes from "./index.module.scss";
import { selectTranslation } from "../../features/Translation/TranslationSlice";
import TEXT from "../../config/text";
import REQUESTS from "../../api/requests";

import UserPackageListDrawer from "./components/UserPackageListDrawer";
import InitialParams from "../../components/InitialParams";
import {
  createSearchParams,
  useNavigate,
} from "react-router-dom";
const { confirm } = Modal;

function UserPackageList() {
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [ediPackage, setEdiPackage] = useState(null);
  const [date, setDate] = useState("");
  const [userPackageList, setUserPackageList] = useState([]);
  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState({
    comment: "",
  });
  const [imaConfigs, setImaConfigs] = useState(null);
  const widgets = useSelector(selectwidgets);

  InitialParams(setLimit, setCurrentPage, setSearch, setSort);

  const navigate = useNavigate();
  const goToPosts = (params) =>
    navigate({
      search: `?${createSearchParams(params)}`,
    });

  const translation = useSelector(selectTranslation);
  const getIMAConfig = () => {
    function callback(data) {
      setImaConfigs(data);
    }
    REQUESTS.IMA_CONFIG.GET(callback);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <Input
          allowClear
          autoFocus
          placeholder="Type text here"
          value={search[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
        />
      );
    },
    filterIcon: () => {
      return (
        <>
          <SearchOutlined />
        </>
      );
    },
  });

  const deleteTable = (id) => {
    const callback = (data) => {
      getUserPackageList();
    };

    const errorCallback = (data) => {};

    REQUESTS.USER_PACKEGES.DELETE(id, callback, errorCallback);
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "delete":
        showPropsConfirm(item.id);
        break;

      case "edit":
        setEdiPackage(item);
        setVisible(true);
        break;

      default:
        break;
    }
  };

  const showPropsConfirm = (id) => {
    confirm({
      content: "Are you sure you want to delete?",
      okText: "DELETE",
      okType: "default",
      okButtonProps: {
        disabled: false,
        style: {
          backgroundColor: "#bf4342",
          color: "#fff",
        },
        loading: loading,
      },

      cancelText: "CANCEL",
      onOk() {
        deleteTable(id);
      },

      onCancel() {},
    });
  };

  const handleSwichClick = (e, item) => {
    const body = {
      id: item.id,
    };
    function callback() {
      getUserPackageList();
    }

    function errorCallback(err) {}

    REQUESTS.USER_PACKEGES.EDIT_RECOMMENDING(body, callback, errorCallback);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      align: "center",
      render: (text, record, index) => {
        if (currentPage === 1) {
          return index + 1;
        } else {
          return limit * (currentPage - 1) + index + 1;
        }
      },
    },
    {
      title: `${translation["Name"] || TEXT["Name"]}`,
      dataIndex: "name",
      align: "center",
      key: "name",
      ...getColumnSearchProps(),
    },

    {
      title: `${translation["Schedule"] || TEXT["Schedule"]}`,
      dataIndex: "schedule",
      align: "center",
      key: "schedule",
      sorter: true,
    },

    {
      title: `${translation["Price"] || TEXT["Price"]}`,
      dataIndex: "price",
      align: "center",
      sorter: true,
    },

    {
      title: `${translation["Credit needed"] || TEXT["Credit needed"]}`,
      dataIndex: "activation_needed",
      align: "center",
      sorter: true,
    },

    {
      title: `${translation["Recommended"] || TEXT["Recommended"]}`,
      dataIndex: "recommended",
      align: "center",
      key: "recommended",
      render: (text, record, index) => {
        return (
          <Switch
            checked={record.recommended === false ? false : true}
            disabled={record.recommended === false ? false : true}
            style={{
              backgroundColor: record.recommended ? "#1890ff" : undefined,
            }}
            onClick={(e) => handleSwichClick(e, record)}
          />
        );
      },
    },

    {
      title: `${translation["Description"] || TEXT["Description"]}`,
      dataIndex: "description",
      align: "center",
      with: "100px",
      render: (record, text, index) => {
        if (record) {
          const content = record;
          return (
            <Popover content={content}>
              <div className={classes["ref-link"]}>{record}</div>
            </Popover>
          );
        }
      },
      ...getColumnSearchProps(),
    },
    {
      title: `${translation["Package for"] || TEXT["Package for"]}`,
      key: "package_for",
      align: "center",
      filters: [
        {
          text: "Web",
          value: "for_web",
        },
        {
          text: "Reseller",
          value: "for_reseller",
        },
      ],

      render: (record, text, index) => {
        if (record) {
          if (record.for_web && record.for_reseller) {
            return " Web/Reseller";
          } else if (record.for_web) {
            return "Web";
          } else if (record.for_reseller) {
            return "Reseller";
          }
        }
      },
    },
    widgets &&
    widgets.google_ima &&
    widgets.google_ima.enabled &&
    widgets.google_ima.enabled === "true"
      ? {
          title: `${translation["Ads Enabled"] || TEXT["Ads Enabled"]}`,
        key: "has_ads",
        align: "center",
          filters: [
            {
              text: "On",
              value: "on",
            },
            {
              text: "Off",
              value: "off",
            },
          ],
          render: (record, text, index) => {
            if (record) {
              if (record.has_ads && imaConfigs && imaConfigs.is_enable) {
                return "Yes";
              } else {
                return "No";
              }
            }
          },
        }
      : {},
    {
      title: "",
      dataIndex: "action",
      align: "center",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          buttons={[
            {
              key: "edit",
              text: `${translation["Edit"] || TEXT["Edit"]}`,
              icon: <EditOutlined />,
            },
            {
              key: "delete",
              text: `${translation["Delete"] || TEXT["Delete"]}`,
              icon: <DeleteOutlined />,
            },
          ]}
        />
      ),
    },
  ];

  const getUserPackageList = () => {
    setLoading(true);
    const query = {
      page: currentPage,
      limit,
      pagination: 1,
      sort: JSON.stringify(sort),
      search: {},
      filter: {},
    };

    if (search.schedule) {
      query.search["schedule"] = search.schedule[0];
    }

    if (search.description) {
      query.search["description"] = search.description[0];
    }

    if (search.name) {
      query.search["name"] = search.name[0];
    }

    if (search.package_for) {
      if (search.package_for[0] === "for_web") {
        query.filter.for_web = true;
      } else if (search.package_for[0] === "for_reseller") {
        query.filter.for_reseller = true;
      }
    }

    if (search.has_ads) {
      if (search.has_ads[0] === "on") {
        query.filter.has_ads = true;
      } else if (search.has_ads[0] === "off") {
        query.filter.has_ads = false;
      }
    }

    if (query.search) {
      query.search = JSON.stringify(query.search);
    }

    if (query.filter) {
      query.filter = JSON.stringify(query.filter);
    }

    if (date && date[0]) {
      let from = new Date(date[0]);
      let to = new Date(date[1]);
  
      // Strip the time part by setting hours, minutes, seconds, and milliseconds to zero
      from.setHours(0, 0, 0, 0);
      to.setHours(0, 0, 0, 0);
      to.setDate(to.getDate() + 1); // Add one day to include the whole end date
  
      query.between = JSON.stringify({
          createdAt: {
              from,
              to,
          },
      });
  }

    goToPosts(query);

    REQUESTS.USER_PACKEGES.GET(query, (data) => {
      setTotal(data.count);
      // setLimit(data.limit);
      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
      setLoading(false);
      setUserPackageList(data.rows);
    });
  };

  useEffect(() => {
    getIMAConfig();
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {

    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    
    setSearch(filters);

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const onClickAdd = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
    setEdiPackage(null);
  };

  useEffect(() => {
    let timout = setTimeout(() => {
      getUserPackageList();
    }, 500);
    return () => {
      clearTimeout(timout);
    };
  }, [search, currentPage, limit, sort, date]);

  const followAddElement = {
    setCurrentPage: setCurrentPage,
    setSearch: setSearch,
    setSort: setSort,
    setLimit: setLimit,
    setTotal: setTotal,
    setDate: setDate,
  };

  return (
    <div className={classes["package_list_table"]}>
      <div className={classes["package_list_title"]}>
        <p className={classes["package_title-text"]}>
        Package Pricing
        </p>
        <Button
          className={classes["package_add"]}
          type="primary"
          icon={<PlusOutlined />}
          onClick={onClickAdd}
        />
      </div>
      <Table
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={userPackageList}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
          // defaultPageSize: 1,
        }}
        scroll={{ x: "max-content" }}
        size="small"
      />

      <UserPackageListDrawer
        visible={visible}
        onClose={closeDrawer}
        current={ediPackage}
        getData={getUserPackageList}
        translation={translation}
        followAddElement={followAddElement}
        imaConfigs={imaConfigs}
      />
    </div>
  );
}

export default UserPackageList;
