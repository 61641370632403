import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export const getColumnSearchPropsName = () => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
    <div style={{ padding: 8 }}>
      <Input
        allowClear
        autoFocus
        placeholder={`Search Name and Surname`}
        value={selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
          confirm({ closeDropdown: false });
        }}
        onPressEnter={() => confirm()}
      />
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilter: (value, record) => {
    const searchText = value.toLowerCase();
    const combinedName = `${record.name} ${record.surname}`.toLowerCase();
    return combinedName.includes(searchText);
  },
});
