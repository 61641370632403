export default function getMyDate(date) {
    const dateObj = new Date(date);

    // Format the date to Asia/Jerusalem timezone
    const options = {
        timeZone: 'Asia/Jerusalem',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    };

    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(dateObj);

    return formattedDate.replace(',', ''); // Remove the comma if present
}
