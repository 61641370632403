import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectwidgets } from "../../../features/widgets/selector";
import CardContainer from "./CardContainer";
import "../styles/cardStyle.scss";
import REQUESTS from "../../../api/requests";
import ICONS from "../../../config/icons";
import { selectTranslation } from "../../../features/Translation/TranslationSlice";
import TEXT from "../../../config/text";

export default function DashboardCards({ date, dateType }) {
  const [devices, setDevices] = useState({});
  const [resellers, setResellers] = useState([]);
  const [resellersActivations, setResellersActivations] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [devicePayment, setDevicePayment] = useState({});
  const [currency, setCurrency] = useState(null);
  const [yesterdayPayment, setYesterdayPayment] = useState(null); // State for yesterday's payment
  const translation = useSelector(selectTranslation);
  const widgets = useSelector(selectwidgets);
  const [combinedTotal, setCombinedTotal] = useState(null); // New state for combined total

  const fetchData = (query = {}) => {
    getTotalDevices(query);
    getPlatforms(query);
    getPlaylistsInfo(query);
    getRegisteredResellers(query);
    getResellersActivations(query);
    getDevicePayment(query);
  };

  const getPlaylistsInfo = (query) => {
    REQUESTS.STATISTICS.TOTALS(query, setPlaylists);
  };

  const getPlatforms = (query) => {
    REQUESTS.STATISTICS.COUNT_USED_DEVICES(query, (data) => {
      const newData = Object.entries(data).map(([name, platform]) => ({
        name,
        ...platform,
      }));
      setPlatforms(newData);
    });
  };

  const getTotalDevices = (query) => {
    REQUESTS.TOTAL_DEVICES(query, setDevices);
  };

  const getRegisteredResellers = (query) => {
    REQUESTS.RESELLERS_REGISTERED(query, setResellers);
  };

  const getResellersActivations = (query) => {
    REQUESTS.RESELLERS_ACTIVATION(query, (data) => {
      setResellersActivations(data);
      calculateCombinedTotal(data, devicePayment);
    });
  };

  const getDevicePayment = (query) => {
    REQUESTS.DEVICE_PAYMENT(query, (data) => {
      setDevicePayment(data);
      REQUESTS.DEVICE_PAYMENT_YESTERDAY(query, (yesterdayData) => {
        setYesterdayPayment(yesterdayData?.total || 0);
      });
      calculateCombinedTotal(resellersActivations, data);
    });
  };

  const getPaymentSettings = () => {
    REQUESTS.PAYMENT_SETTINGS((data) => {
      setCurrency(data.currency);
    });
  };

  const calculateCombinedTotal = (resellerData, paymentData) => {
    if (!resellerData || !paymentData) return; // Ensure both data objects are available
  
    const resellerTotal = resellerData.total ? parseFloat(resellerData.total) : 0;
    const completedTotal = paymentData.total_with_status?.COMPLETED
      ? parseFloat(paymentData.total_with_status.COMPLETED)
      : 0;
  
    setCombinedTotal(resellerTotal + completedTotal);
  };
  
  useEffect(() => {
    if (resellersActivations && devicePayment) {
      calculateCombinedTotal(resellersActivations, devicePayment);
    }
  }, [resellersActivations, devicePayment]);
  
  
  useEffect(() => {
    getPaymentSettings();

    const query = {};
    if (date && dateType) {
      query.data_type = dateType;
      query.date = date;
    }
    fetchData(query);
  }, [date, dateType]);

  return (
    <div className="dashboard-information-cards-container">

<CardContainer
  title={
    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
      <span>{"Payments"}</span>
      <div>
      <span>Completed: </span>
        <span className="dashboardCard__values">{"$"}
          {devicePayment && devicePayment.total_with_status ? (
            <>
              {parseFloat(combinedTotal)}{" "}
              <span className="currency-style">{currency}</span>
            </>
          ) : 0}
        </span>
      </div>
    </div>
  } 
  icon={ICONS.PAYMENT_DONE} 
  iconColor={"#58b68a"}
>
<div className="payment-done-wrapper">


    {devicePayment && devicePayment.total_with_status && (
      <div>
          <span className="text">Total: </span>
          <span className="dashboardCard__values">{"$"}
            {devicePayment.total ? parseFloat(devicePayment.total) : 0}{" "}
            <span className="currency-style">{currency}</span>
          </span>

        <div>
        <span className="text">Pending: </span>
<span className="dashboardCard__values">{"$"}
  {devicePayment.pending ? parseFloat(devicePayment.pending).toFixed(2) : "0.00"}{" "}
  <span className="currency-style">{currency}</span>
</span>
        </div>
        
      </div>
    )}
</div>
</CardContainer>


        <CardContainer
    title={
    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
      <span>{"Platforms"}</span>
      <div>
      <span>Total Count: </span>
        <span className="dashboardCard__values">
          {devicePayment && devices.total_ds ? (
            <>
              {parseFloat(devices.total_ds)}
            </>
          ) : 0}
        </span>
      </div>
    </div>

} icon={ICONS.DEVICES} iconColor={"#e76161"}
>
      
        <div className="dashboardCard__platforms platforms">
          {platforms.map((platform, index) => {
            return (
              <div key={index}>
              <span className="text">
              {platform &&
                    platform.name &&
                    platform.name[0].toUpperCase() + platform.name.substring(1)}
                  :
              </span>
                <span className="dashboardCard__values"> {platform.count}</span>
              </div>
            );
          })}
        </div>
      </CardContainer>
      
      {widgets &&
        widgets.reseller &&
        widgets.reseller.enabled &&
        widgets.reseller.enabled === "true" && (
          <CardContainer
            title={"Resellers"}
            icon={ICONS.RESELLER_ACTIVATION}
            iconColor={"#934fdf"}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              paddingBottom: 16,
            }}
          >
            <div className="dashboardCard__resellers-activations">
              <div>
                <div>
                  <span className="text">Activated Devices: </span>

                  <span className="dashboardCard__values">
                    {resellersActivations && resellersActivations.res_act_total}
                  </span>
                </div>
                <div>
                  <span className="text">Payments: </span>

                  <span className="dashboardCard__values">{"$"}
                    {resellersActivations &&
                      resellersActivations.total &&
                      parseFloat(resellersActivations.total)}{" "}
                  </span>
                  <span className="currency-style">{currency}</span>
                </div>
              </div>

              <div>
                <div>
                <span className="text">Sub resellers: </span>
                <span className="dashboardCard__values">
                  {resellers.subresellers_count}
                </span>
                </div>
                <div>
                <span className="text">Regesterd: </span>
                <span className="dashboardCard__values">
                  {resellers.reselleres}
                </span>

                </div>
              </div>
            </div>
          </CardContainer>
        )}
    </div>
  );
}
