export default function getDate(date) {
  const dateObj = new Date(date);

  // Format the date to Asia/Jerusalem timezone
  const options = {
      timeZone: 'Asia/Jerusalem',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
  };

  const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(dateObj);

  return formattedDate; // This will return the date in DD/MM/YYYY format
}
