import { useState, useEffect } from "react";
import { DatePicker, Space, Select } from 'antd';
import moment from 'moment';

import DashboardCards from "./components/DashboardCards";
import DashboardCardsDatetype from "./components/DashboardCardsDatetype";
import UsedDevicesProgres from "./components/UsedDevicesProgres";
import PaymentStatistics from "./components/PaymentStatistics";
import ServerStatistics from "./components/ServerStatistics";

import classes from "./index.module.scss";

const PickerWithType = ({ type, onChange, defaultValue }) => {
  const dateFormat = "YYYY-MM-DD";
  return (
    <DatePicker
      picker={type}
      onChange={onChange}
      defaultValue={defaultValue ? moment(defaultValue, dateFormat) : moment()}
      format={dateFormat}
    />
  );
};

function Dashboard() {
  const [date, setDate] = useState(moment().startOf('month').format("YYYY-MM-DD"));
  const [dateType, setDateType] = useState("week");

  useEffect(() => {
    // Initialize data on first load
    handleDateChange(moment(date, "YYYY-MM-DD"));

    // Example: Delaying the setting of dateType
    const timeoutId = setTimeout(() => {
      setDateType("month"); // Change to "month" after 2 seconds
    }, 500);

    // Cleanup to prevent memory leaks
    return () => clearTimeout(timeoutId);
  }, []);

  const handleDateChange = (value) => {
    const selectedDate = value ? moment(value).startOf('day') : moment().startOf('day'); // Ensure time is stripped
    const year = selectedDate.year();
    const month = selectedDate.month() + 1; // months are zero-indexed in JS
    const day = selectedDate.date();

    let formattedDate;

    if (dateType === "year") {
      formattedDate = `${year}-01-01`;
    } else if (dateType === "month") {
      formattedDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    } else if (dateType === "week") {
      formattedDate = selectedDate.startOf('week').format('YYYY-MM-DD');
    }

    setDate(formattedDate);
  };

  const getSelectedNameLabel = () => {
    if (dateType === "year") {
      return moment(date).format('YYYY');
    } else if (dateType === "month") {
      return moment(date).format('MMMM YYYY');
    } else if (dateType === "week") {
      return moment(date).format('dddd, MMMM D, YYYY');
    }
    return "";
  };

  return (
    <div>
      <DashboardCards />
      <ServerStatistics />
      <div className={classes["statistics"]}>
      </div>

      <div className={classes["statistics"]}>
        <UsedDevicesProgres date={date} dateType={dateType} />
        <PaymentStatistics date={date} dateType={dateType} />
      </div>

      <div className={classes["server-statistics"]}>
        <div className={classes['range-picker']}>
          <h3 className={classes["title"]}>
            {`${dateType === "year" ? "Year" : dateType === "month" ? "Month" : "Week"}: ${getSelectedNameLabel()}...`}
            <Space>
              <Select value={dateType} onChange={(value) => {
                setTimeout(() => setDateType(value), 1000); // Timeout of 1 second
              }}>
                <Select.Option value="year">Year</Select.Option>
                <Select.Option value="month">Month</Select.Option>
                <Select.Option value="week">Week</Select.Option>
              </Select>
              <PickerWithType
                type={dateType}
                onChange={handleDateChange}
                defaultValue={moment(date, "YYYY-MM-DD")}
              />
            </Space>
          </h3>
        </div>
        <DashboardCardsDatetype date={date} dateType={dateType} />
      </div>
    </div>
  );
}

export default Dashboard;
