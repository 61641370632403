import { useEffect, useState } from 'react';

import { Drawer, DatePicker, Divider } from 'antd';

import { DashboardOutlined } from '@ant-design/icons';

import REQUESTS from '../../api/requests';

import styles from "./index.module.scss"

const { RangePicker } = DatePicker;

export default function FreeDNDStatistic({ open, onClose, current }) {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [date, setDate] = useState([]);

    const getData = () => {
        const query = {
            free_dns_id: current.id
        };

        if (date && date[0] && date[1]) {
            query["start_date"] = date[0]
            query["end_date"] = date[1]
        }

        REQUESTS.FREE_DNS_STATISTICS(query, (data) => {
            setData(data)
            let generalCount = data.reduce((acc, item) => {
                return acc + item.device_count
            }, 0);

            setTotal(generalCount);

        }, (error) => { })
    }

    const onChange = (date, dateString) => {
        setDate(dateString);
    };

    useEffect(() => {
        if (open && current) {
            getData();
        } else {
            setData([]);
            setDate(null);
            setTotal(null);
        }

    }, [current, open]);

    useEffect(() => {
        if (date && date.length) {
            getData();
        }
    }, [date])

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title="Free DNS statistic"
            destroyOnClose={true}
        >
            <RangePicker onChange={onChange} /><br />

            <Divider style={{ margin: "10px 0" }} />
            <ol className={styles['list']}>
                {data.map((item, index) => (
                    <li key={index}>
                        <strong>{item.model} : </strong>{item.device_count}
                    </li>
                ))}
            </ol>
            {data.length > 0 ? <Divider style={{ margin: "10px 0" }} /> : null}
            <strong>
                <DashboardOutlined /> Total: {total}
            </strong>
        </Drawer>
    )
}
