import React, { useEffect, useState } from "react";

import { Table, DatePicker, Empty } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

import REQUESTS from "../../../api/requests";
import { useSelector } from "react-redux";
import getMyDate from "../../../components/getMyDate";
import { getColumnSearchProps } from "../../../utils/index";
import { selectTranslation } from "../../../features/Translation/TranslationSlice";
import TEXT from "../../../config/text";

const { RangePicker } = DatePicker;

export default function ResellerActivationHistory({ current }) {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(10);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [date, setDate] = useState("");
  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState(null);

  const translation = useSelector(selectTranslation);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      align: "center",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: `${translation["Method"] || TEXT["Method"]}`,
      dataIndex: "method",
      align: "center",
      ...getColumnSearchProps(),
    },
    {
      title: `${translation["Transaction id"] || TEXT["Transaction id"]}`,
      dataIndex: "transaction_id",
      align: "center",
      ...getColumnSearchProps(),
    },
    {
      title: `${translation["Count"] || TEXT["Count"]}`,
      dataIndex: "count",
      align: "center",
      sorter: true,
      render: (record, text, index) => {
        return `${record}`;
      },
    },
    {
      title: `${translation["Price"] || TEXT["Price"]}`,
      dataIndex: "price",
      sorter: true,
      align: "center",
      render: (record, text, index) => {
        return `${record}`;
      },
    },
    {
      title: `${translation["Created date"] || TEXT["Created date"]}`,
      sorter: true,
      dataIndex: "createdAt",
      align: "center",
      render: (record, text, index) => {
        return record && getMyDate(record);
      },
      filterDropdown: ({ }) => (
        <div
          style={{
            padding: 8,
          }}
        >
          <RangePicker
            size="small"
            onChange={(e, dateString) => setDate(dateString)}
            renderExtraFooter={() => "extra footer"}
          />
        </div>
      ),
      filterIcon: (filtered) => <CalendarOutlined />,
    },
  ];

  const getActivationHistory = () => {
    setLoading(true);
    const query = {
      page: currentPage,
      limit,
      pagination: 1,
      sort: JSON.stringify(sort),
      filter: JSON.stringify({ reseller_id: current?.id }),
    };

    if (search) {
      query.search = {};

      if (search.method) {
        query.search["method"] = search.method[0];
      }

      if (search.transaction_id) {
        query.search["transaction_id"] = search.transaction_id[0];
      }

      query.search = JSON.stringify(query.search);
    }

    if (date && date[0]) {
      let from = new Date(date[0]);
      let to = new Date(date[1]);
  
      // Strip the time part by setting hours, minutes, seconds, and milliseconds to zero
      from.setHours(0, 0, 0, 0);
      to.setHours(0, 0, 0, 0);
      to.setDate(to.getDate() + 1); // Add one day to include the whole end date
  
      query.between = JSON.stringify({
          createdAt: {
              from,
              to,
          },
      });
  }

    REQUESTS.RESELLER_ACTIVATIONS_HISTORY(
      query,
      (data) => {
        setLoading(false);
        setTotal(data.count);
        setLoading(false);
        setData(data.rows);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    setSearch(filters);

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  useEffect(() => {
    let timout;
    if (current) {
      timout = setTimeout(() => {
        getActivationHistory();
      }, 500);
    }
    
    return () => {
      clearTimeout(timout);
    };
  }, [search, currentPage, limit, sort, date, current]);

  useEffect(() => {
    if (!current) {
      setDate('')
    }
  }, [current]);

  return (
    <div>
      <div className="table-title">Credits History</div>

      <Table
        loading={loading}
        columns={columns}
        rowKey="id"
        dataSource={data}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        scroll={{ x: "max-content" }}
        size="small"
        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" /> }}
      />
    </div>
  );
}
