const CONSTANTS = {
    JWT: "JWT",
    REMEMBER_ME: "REMEMBER_ME",

    APP_NAME: window.APP_NAME || "XUI Admin",
    API_HOST: window.API_URL || "https://apixui.tvplayers.net",

    APP_COLOR: "black",
    LINK_COLOR: "white",
};

export default CONSTANTS;
